@import '../../variables.css';
.dimmer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .dimmer-content {
    padding-top: 23rem;
    text-align: center;
    font-size: 1.5rem;
  }
  .dimmer button {
    font-size: 18px;
    padding-top: 20px;
    color: var(--primary-color);
    cursor: pointer;
    border: none;
    background: none;
  }

  .dimmer p {
    font-weight: 200;
  }

  .dimmer span {
    font-weight: 400;
  }
  