@import './variables.css';

html {
  display: flex;
  flex-direction: column;
  align-items: center;
}
body {
  max-width: 1440px;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-color);
}

.disclaimer {
  position: absolute;
  bottom: 0px;
  font-weight: 200;
}

.disclaimer a {
  text-decoration: solid;
  color: var(--terciary-color);
}